@import './src/styles.scss';
@import './components/styles.scss';

.accordion-list {

    // Table
    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 12px;
        
        td {
            height: 20px;
            border: 1px solid var(--app-primary-border-color);
        }
    }

    // Scrolled Container
    .scrolled-container {
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    // Utilities
    .center-t {
        text-align: center;
    }

    .left-t {
        text-align: left;
        padding-left: 5px;
    }

    .right-t {
        text-align: right;
        padding-right: 5px;
    }
}