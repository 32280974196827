@import '../open-orders-table/styles.scss';
@import '../exposure-tool-table/styles.scss';
@import '../order-history-table/styles.scss';

.datatables {
    display: flex;
    flex-direction: column;
    height: 100%;
    // Header
    .header {
        display: flex;
        justify-content: space-between;
        // border-bottom: 1px solid var(--app-primary-border-color);
        background-color: var(--account-status-bg-color);
        padding: 5px;
        .tabs{
            background-color: var(--app-primary-bg-color);
            border-radius: 8px;
            padding: 5px;
            button {
                font-family: var(--app-primary-font-family);
                height: 30px;
                background-color: transparent;
                color: var(--app-secondary-text-color);
                outline: none;
                border-radius: 8px;
                cursor: pointer;
                padding: 5px 15px;    
                font-size: 12px;
                border-color: transparent;
                margin-right: 5px;
                &:hover{
                    background-color: var(--app-primary-color);
                    color: #fff;
                }
                &.active {
                    color: #fff;
                    background-color: var(--app-primary-color);
                }
            }
        }
        
        .controls{
            display: flex;
           .hide-table {
            border: none;
            font-size: 12px;
            color: var(--app-primary-text-color);
            text-decoration: none;
            background-color: transparent;
            cursor: pointer;
            } 
        }        
    }
    
    // Tables
    .content {
        height: 100%;
        overflow-y: scroll;
        font-size: 12px;

        table {
            width: 100%;
            height: 100%;
            border-spacing: 0;
            border-collapse: collapse;
            table-layout: fixed;
            background-color: var(--app-secondary-bg-color);
            thead {
                top: 0;
                position: sticky;
                background-color: var(--app-primary-bg-color);
                cursor: default;
            }

            tbody {
                tr {
                    &:not(.table-title):hover {
                        background-color: var(--table-tr-bg-color-hover);
                    }
                }
            }

            tr {
                padding-left: 5px;
                padding-right: 5px;
                &:nth-child(even){
                    background-color: var(--table-tr-bg-color-hover);
                }
            }

            th {
                color: var(--app-secondary-text-color);
                &:hover {
                    color: var(--app-primary-color);
                    background-color: var(--app-secondary-bg-color);
                }
            }
            
            td, th {
                padding: 5px 0;
                min-width: 80px;
                // border-bottom: 1px solid var(--app-primary-border-color);

                &:not(:first-child) {
                    text-align: center;
                }

                &:first-child {
                    padding-left: 10px;
                    text-align: left;
                }

            }
            td{
                color: var(--app-secondary-text-color);
            }
        }
    }

    .align-r {
        text-align: right !important;
    }
}