#order-create-modal {
    width: 380px;

    .body {
        display: flex;
        flex-direction: column;
        padding: 5px;
    }

    .tabs {
        display: flex;

        button {
            flex: 1;
            font-family: inherit;
            outline: none;
            cursor: pointer;
        }
    }

    .order-type {
        flex: 1;
        width: 100%;
        flex: 1 1;
        width: 100%;
        background-color: var(--app-primary-bg-color);
        border-radius: 8px;
        padding: 5px;
        border: none;
        margin: 10px 0;
        button {
            height: 35px;
            border-top: 1px solid var(--modal-secondary-border-color);
            border-right: none;
            border-bottom: 1px solid var(--modal-secondary-border-color);
            border-left: none;
            font-size: 16px;
            font-weight: 300;
            background-color: transparent;
            transition: all 0.25s linear;
            -o-transition: all 0.25s linear;
            -moz-transition: all 0.25s linear;
            -webkit-transition: all 0.25s linear;
            color: var(--app-primary-text-color);
            border: none;
            border-radius: 8px;
            font-weight: 500;
            &.selected {
                color: #fff;
                background-color: var(--app-primary-color);
            }
        }
    }

    form {
        padding: 10px 30px;

        label {
            margin-top: 5px;
            flex: 1;
            display: block;
            color: var(--app-primary-text-color);
            font-size: 12px;
            font-weight: 500;

            &:first-child {
                padding-top: 5px;
            }
        }

        input {
            margin: 2px 1px;
            padding-left: 5px;
            width: 100%;
            height: 30px;
            max-height: 30px;
            font-size: 16px;
            background-color: transparent;
            color: var(--app-primary-text-color);
            border: 1px solid var(--modal-border-color);
            outline: none;
            border-radius: 8px;
            &#create-amount{
                height: 40px;
                max-height: 40px;
            }
        }

        .symbol {
            font-family: var(--app-primary-font-family);
            font-size: 32px;
            text-align: center;
            font-weight: 500;
        }

        .order-side {
            button {
                height: 50px;
                border: 1px solid var(--modal-tertiary-border-color);
                background-color: transparent;
                transition: all 0.25s linear;
                -o-transition: all 0.25s linear;
                -moz-transition: all 0.25s linear;
                -webkit-transition: all 0.25s linear;
               &:first-child{
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
               }

               &:last-child{
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
               }

                &.selected {
                    color: var(--app-primary-text-color);

                    &.sell {
                        color: #FFF;
                        background-color: var(--app-sell-color);
                        border-color: var(--app-sell-color);
                    }

                    &.buy {
                        color: #fff;
                        background-color: var(--app-buy-color);
                        border-color: var(--app-buy-color);
                    }
                }
            }
        }

        .amount {
            .amount-buttons {
                margin-top: 5px;
                display: flex;

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    width: 100%;
                    height: 25px;
                    padding: 0;
                    font-size: 14px;
                    color: var(--app-tertiary-text-color);
                    background-color: var(--app-primary-color);
                    transition: all 0.25s linear;
                    -o-transition: all 0.25s linear;
                    -moz-transition: all 0.25s linear;
                    -webkit-transition: all 0.25s linear;
                    cursor: pointer;
                    border-radius: 4px;
                    &:not(:last-child) {
                        margin-right: 2px;
                    }

                    &:hover {
                        opacity: 0.85;
                    }
                }
            }
        }

        .takeprofit-stoploss {
            display: flex;

            .col {
                display: flex;
                flex-direction: column;
                height: 110px;

                :not(.mid-col) {
                    flex: 1;
                }

                &.flex-end {
                    justify-content: flex-end;
                }
            }

            .mid-col {
                padding-top: 30px;
            }

            label {
                flex: none !important;
                margin-bottom: 2px;
                height: 20px;

                &.take-profit-label {
                    color: var(--app-buy-color);
                }

                &.stop-loss-label {
                    color: var(--app-sell-color);
                }
            }

            img {
                width: 45px;
            }
        }

        .create-button {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
            width: 100%;
            height: 35px;
            font-size: 14px;
            color: var(--app-tertiary-text-color);
            background-color: var(--app-buy-color);
            border: none;
            transition: all 0.25s linear;
            -o-transition: all 0.25s linear;
            -moz-transition: all 0.25s linear;
            -webkit-transition: all 0.25s linear;
            cursor: pointer;
            border-radius: 8px;
            i {
                margin-left: 5px;
                display: inline-block;
                font-size: 20px;
                animation: spin 2s infinite;
                -o-animation: spin 2s infinite;
                -moz-animation: spin 2s infinite;
                -webkit-animation: spin 2s infinite;
            }

            &:disabled {
                // opacity: 0.8;
                color: var(--app-buy-color);
                background-color: transparent;
                border: 1px solid var(--app-primary-border-color)
            }
            
            &.red {
                background-color: var(--app-sell-color);
            }

            &:not(&:disabled):hover {
                opacity: 0.85;
            }
        }
    }
}

#order-modify-modal, #order-close-modal, #order-delete-modal  {
    width: 340px;

    .body {
        padding: 10px;
    }

    label {
        margin-top: 5px;
        flex: 1;
        display: block;
        color: var(--app-primary-text-color);
        font-size: 14px;

        &:first-child {
            padding-top: 5px;
        }
    }

    input {
        margin: 2px 1px;
        padding-left: 5px;
        width: 100%;
        height: 30px;
        max-height: 30px;
        font-size: 16px;
        background-color: transparent;
        color: var(--app-primary-text-color);
        border: 1px solid var(--app-buy-color);
        outline: none;
        border-radius: 8px;
        &#modify-price{
            height: 40px;
            max-height: 40px;
        }
    }

    form {
        .symbol {
            height: 30px;
            border-bottom: 1px solid var(--app-primary-border-color); 
            text-align: center;

            span {
                border-bottom: 2px solid var(--app-buy-color);
                padding: 6px;
                font-weight: 500;
            }
        }

        .description {
            font-size: 22px;
            margin-top: 20px;
            text-align: center;
        }

        .submit-button {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            margin: 10px 0 0;
            width: 100%;
            height: 35px;
            font-size: 14px;
            color: var(--app-tertiary-text-color);
            background-color: var(--app-buy-color);
            border: none;
            transition: all 0.25s linear;
            -o-transition: all 0.25s linear;
            -moz-transition: all 0.25s linear;
            -webkit-transition: all 0.25s linear;
            cursor: pointer;
            border-radius: 8px;

            i {
                margin-left: 5px;
                display: inline-block;
                font-size: 20px;
                animation: spin 2s infinite;
                -o-animation: spin 2s infinite;
                -moz-animation: spin 2s infinite;
                -webkit-animation: spin 2s infinite;
            }

            &:disabled {
                // opacity: 0.8;
                color: var(--app-buy-color);
                background-color: transparent;
                border: 1px solid #30324a;
            }
            
            &:not(&:disabled):hover {
                opacity: 0.85;
            }
        }
    }
}

#order-modify-modal {
    form {
        .takeprofit-stoploss {
            display: flex;

            .col {
                display: flex;
                flex-direction: column;
                height: 110px;

                :not(.mid-col) {
                    flex: 1;
                }

                &.flex-end {
                    justify-content: flex-end;
                }
            }

            .mid-col {
                padding-top: 30px;
            }

            label {
                flex: none !important;
                margin-bottom: 2px;
                height: 20px;
                font-size: 12px;
                font-weight:500;
                &.take-profit-label {
                    color: var(--app-buy-color);
                }

                &.stop-loss-label {
                    color: var(--app-sell-color);
                }
            }

            img {
                width: 45px;
            }
        }
    }
}

#order-delete-modal {
    form {
        .message {
            display: flex;
            flex-direction: column;
            font-family: Lato,sans-serif;
            font-size: 16px;
            margin-top: 10px;
            text-align: center;
            padding: 0 30px;
        }
    }
}