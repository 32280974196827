.accordion-list {
    // Container
    ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        height: 100%;
        list-style-type: none;

        // Item
        li {
            background-color: var(--account-status-bg-color);
            border-radius: 8px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 5px;
                height: 33px;
                font-family: var(--app-primary-font-family);
                font-size: 12px;

                i {
                    font-size: 18px;
                }
            }

            .body {
                display: flex;
                padding-left: 5px;
                max-height: 0px;
                overflow: hidden;
                transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                -webkit-transition: all .3s ease-in-out;

                & > div {
                    i {
                        display: block;
                    }

                    &:first-child {
                        min-width: 20px;
                        display: flex;
                        padding-right: 5px;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    }

                    &:last-child {
                        border-left: 1px solid var(--app-primary-border-color);
                    }    
                }

                &.active {
                    max-height: 500px;
                }
            }
        }
    }
}