.header-menu {
    display: flex;
    align-items: center;
    height: 100%;
    
    .column {
        display: flex;    
        flex-direction: column;
        height: 100%;
        font-size: 12px;
        color: var(--app-primary-text-color);

        & > div {
            padding: 3px 0;
            flex: 1;
            &.tm-acc-title {
                font-size: 10px;
                color: var(--app-secondary-text-color);
            }
            &:last-child {
                // border-top: 1px solid var(--app-primary-border-color);
            }
        }

        &:first-child {
            flex: 5;
            
            .tm-acc-title {
                margin-top: 2px;
            }
            
            & > div {
                padding-left: 10px;
                
                &:last-child {
                    margin-top: -8px;
                }
            }
        }

        &:last-child {
            flex: 3;
            // border-left: 1px solid var(--app-primary-border-color);
            text-align: center;

            & > div {
                cursor: pointer;
            }
        }
        &.tm-icon-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            div:hover{
                i{
                    color: var(--app-primary-color);
                }
            }
            i{
                font-size: 16px;
            }
            .settings{
                border: none;
            }
        }
    }

    // .settings {
    //     background-color: var(--app-primary-color);
    //     color: var(--app-tertiary-text-color);
    // }
}