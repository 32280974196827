.order-history-table {
    .toolbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 10px;
        border-bottom: 1px solid var(--app-primary-border-color);

        & > * {
            display: flex;

            & > * {
                margin: 0px 3px;
            }
        }
        
        .flex-end {
            display: flex;
            align-items: center;
        }

        input {
            background-color: transparent;
            border: 1px solid var(--app-primary-border-color);
            color: var(--app-primary-color);
            font-family: inherit;
            font-size: 12px;
            outline: none;
            border-radius: 5px;
            padding: 3px 5px;
            font-size: 10px;
            height: 26px  ;
            background-color: var(--table-input-bg-color);
        }

        .selector {
            letter-spacing: 1px;
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            user-select: none;
            
            .selected {
                position: relative;
                display: inline-block;
                width: 200px;
                border: 1px solid var(--app-primary-border-color);
                cursor: default;
                border-radius: 5px;
                padding: 3px 5px;
                font-size: 10px;
                background-color: var(--table-input-bg-color);
                i {
                    position: absolute;
                    right: 5px;
                    top: 6px;
                }
            }
        
            .list {
                position: absolute;
                width: 200px;
                max-height: 150px;
                border: 1px solid var(--app-primary-border-color);
                background-color: var(--app-primary-bg-color);
                padding-top: 2px;
                padding-left: 5px;
                overflow-x: hidden;
                overflow-y: auto;
                letter-spacing: 0.4px;
                display: none;
                z-index: 5;
            }
            
            .list > div {
                padding: 2px 0;
                cursor: pointer;
            }
            
            .list > div:hover {
                opacity: 0.85;
            }
    
            &:hover .list {
                display: block;
            }
        }

        button {
            padding: 3px;
            background-color: var(--app-primary-color);
            color: var(--app-primary-text-color);
            font-family: inherit;
            font-size: 12px;
            border: none;
            outline: none;
            cursor: pointer;
            border-radius: 8px;
            &:hover {
                opacity: 0.9;
            }           
        }
    }

}

.react-datepicker{
    background-color: var(--account-status-bg-color);
    border-color: var(--app-primary-border-color);
    .react-datepicker__header {
        background-color: var(--app-primary-border-color);
        border-bottom: 1px solid var(--app-primary-border-color);
    }
    .react-datepicker__navigation-icon--previous::before,
    .react-datepicker__navigation-icon--next::before {
        border-color: var(--app-primary-text-color);
    }
    .react-datepicker__current-month, 
    .react-datepicker-time__header, 
    .react-datepicker-year-header, .react-datepicker__day-name, 
    .react-datepicker__day, 
    .react-datepicker__time-name{
        color: var(--app-primary-text-color);
    }    
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    border-bottom-color: var(--app-primary-border-color);
}

.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range {
    background-color: var(--app-primary-color);
}

.react-datepicker__day:hover, 
.react-datepicker__month-text:hover, 
.react-datepicker__quarter-text:hover, 
.react-datepicker__year-text:hover {
    background-color: rgba(76, 104, 238, 0.5);
}