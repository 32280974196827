.watchlist {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    
    // Header
    .watchlist-header {
        display: flex;
        justify-content: space-between;
        padding: 10px 6px;
        font-size: 14px;
        font-weight: 300;
        border-bottom: 1px solid var(--app-primary-border-color);
        height: 42px;
        background-color: var(--account-status-bg-color);
        
        .title {
            font-family: var(--app-primary-font-family) sans-serif;
            font-size: 12px;
            font-weight: 600;
        }

        .description {
            font-size: 10px;
            color: var(--app-secondary-text-color);;
        }

        .controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            .input-group{
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 5px;
                .search-input {
                    margin-right: 2px;
                    width: 100px;
                    height: 26px;
                    padding-left: 5px;
                    font-family: inherit;
                    font-size: 11px;
                    background-color: transparent;
                    color: var(--app-primary-text-color);
                    border: 1px solid var(--app-primary-border-color);
                    outline: none;
                    border-radius: 6px;
                    padding-right: 30px;
                }
                i{
                    position: absolute;
                    padding: 10px;
                    pointer-events: none; 
                    right: 0;
                    color: var(--app-secondary-text-color);
                    font-size: 10px;
                }
            }
            .symbols-button {
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                color: var(--app-secondary-text-color);
                border: 1px solid var(--app-primary-border-color);
                cursor: pointer;
                font-size: 12px;
                border-radius: 6px;
                &:hover{
                    color: var(--app-primary-text-color);
                }

                i {
                    font-size: 14px;
                }
            }
        }

    }

    // Container
    .watchlist-container {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: thin;
        padding: 5px;
    }

    // Item
    .watchlist-item {
        display: flex;
        flex-direction: column;
        // margin: 0 3px 3px;
        transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -webkit-transition: all 0.1s ease-in-out;
        background-color: var(--account-status-bg-color);
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 5px;
        .header {
            display: flex;
            justify-content: space-between;
            padding: 3px 0 3px 3px;
            font-family: var(--app-primary-font-family);
            font-size: 14px;
            cursor: move;
            .symbol-title{
                font-weight: 600;
            }
            .amount-input {
                padding-left: 5px;
                width: 115px;
                max-height: 30px;
                font-size: 16px;
                background-color: transparent;
                color: var(--app-primary-text-color);
                border: 1px solid var(--app-buy-color);
                outline: none;
            }

            .description {
                font-size: 12px;
                padding-right: 5px;
                color: var(--app-secondary-text-color);
            }
        }

        .body {
            display: flex;
            position: relative;
            .column {
                padding: 6px 3px 10px;
                color: var(--watchlist-text-color);
                cursor: pointer;

                &:last-child .price {
                    justify-content: flex-end;
                }

                &:hover {
                    opacity: 0.8;
                }
                &.tm-buy{
                   .price{
                    justify-content: flex-end;
                   }
                }
            }
            .tm-one-click__trade{
                position: absolute;
                width: 120px;
                background: var(--account-status-bg-color);
                top: 50%;
                left: 50%;
                transform: translate(-50%, 12%);
                display: flex;
                align-items:   center;
                flex-direction: row;
                justify-content: space-around;
                border-radius: 10px 10px 0 0;      
                margin-top: 3px;          
                input{
                    width: 60px;
                    height: 28px;
                    border: 1px solid var(--app-primary-border-color);
                    // border-radius: 5px;
                    background-color: var(--app-secondary-bg-color);
                    color: var(--app-secondary-text-color);
                    text-align: center;
                    outline: none;
                    margin: 3px 0 0px 0;
                    z-index: 1;
                    &:focus{
                        border-color: var(--app-primary-color);                        
                    }
                }
                button{
                    border: 1px solid var(--app-primary-border-color);
                    background-color: var(--app-secondary-bg-color);
                    color: var(--app-secondary-text-color);
                    height: 28px;
                    width: 28px;
                    cursor: pointer;
                    &:hover{
                        background-color: var(--app-primary-color);
                        color: #fff;
                    }
                    &.tm-oct__up{
                        border-radius: 0 8px 8px 0;
                        margin-left: -1px;
                        margin: 3px 3px 0px -1px;
                        i{
                            font-size: 10px;
                        }
                    }
                    &.tm-oct__down{
                        border-radius: 8px 0 0 8px;
                        margin: 3px -1px 0px 3px;

                        i{
                            font-size: 14px;
                        }
                    }                    
                }
            }
            
            .price-label {
                margin: 2px;
                font-size: 10px;
                &.tm-pl-buy{
                    text-align: right;
                }
                &.tm-pl-sell{
                    text-align: left;
                }
            }

            .price {
                display: flex;
                align-items: center;
                white-space: nowrap;
                font-family: 'Lato', 'Poppins', sans-serif;
            }

            .standard {
                font-size: 20px;
            }

            .larger {
                position: relative;
                font-size: 30px;
                font-weight: 700;
                margin-top: -7px;
                margin-left: 2px;
            }

            .smaller {
                position: relative;
                margin-left: 2px;
                margin-top: -18px;
            }
        }
        .footer{
            .column{
                color: var(--app-secondary-text-color);
            }
        }
        .buttons {
            z-index: 1;

            .container {
                width: fit-content;
                display: flex;
                padding: 2px 1px;
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 1px;
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    border: 1px solid var(--app-primary-border-color);
                    color: var(--app-primary-color);
                    cursor: pointer;
                    border-radius: 5px;
                    &.btn-order{
                        &:active{
                            background-color: var(--app-success);
                            color: #fff;
                        }
                    }                    
                    &:hover {
                        background-color: var(--app-primary-color);
                        color: #fff;
                    }
                }
            }
        }
        
        .footer {
            margin-top: 10px;
            font-size: 10px;
            // border-bottom: 1px solid var(--app-primary-border-color);

            .column {
                padding: 0 3px 3px;
            }
        }

        .header, .body, .footer {
            display: flex;
        }

        .column {
            // flex: 1;            
            width: 108.5px;

            &:first-child {
                text-align: left;
                margin-right: 1px;
            }
            
            &:last-child {
                text-align: right;
                margin-left: 1px;
            }
        }
    }

    // Utilities
    .up-direction {
        background: var(--watchlist-up-direction-bg-color);
        background: var(--watchlist-up-direction-color);
    }

    .default-direction {
        background: var(--watchlist-default-direction-bg-color);
        background: var(--watchlist-default-direction-color);
    }

    .down-direction {
        background: var(--watchlist-down-direction-bg-color);
        background: var(--watchlist-down-direction-color);
    }

}

.container.basic {
    .watchlist {
        .analysis-button {
            display: none !important;
        }
    }
}