$light-theme: (
    // --app-logo-url: url('../../images/_/logo-light.png'),
    // --auth-logo-url: url('../../images/_/logo-dark.png'),
    --app-primary-color: #4C68EE, //#0070b7,

    --app-primary-bg-color: #ebedf6,
    --app-secondary-bg-color: #fff,
    
    --app-primary-border-color: #d6d9e2,
    --app-secondary-border-color: RED,

    --app-primary-text-color: #535360,
    --app-secondary-text-color: #2e2e35,
    --app-tertiary-text-color: #F5F5F5,
    --app-blue-text-color: #4C68EE,
    --app-red-text-color: #B8191D,

    --app-primary-font-family: 'Poppins',
    --app-secondary-font-family: 'Oswald',
    --app-tertiary-font-family: 'Lato',

    --app-primary-button-color-hover: #2196f3, //#0B64B1, //2196f3
    --app-primary-button-color: #19193F,
    --app-primary-button-text-color-hover: #CDCDDE,
    --app-primary-button-text-color: #CDCDDE,

    --app-secondary-button-color-hover: #d6a526,
    --app-secondary-button-color: transparent,
    --app-secondary-button-text-color-hover: #CDCDDE,
    --app-secondary-button-text-color: #d6a526,

    --app-tertiary-button-color: #4C68EE,
    --app-tertiary-button-text-color: #FFF,


    --app-scrollbar-track: #DDD,
    --app-scrollbar-thumb: #aaaabc,
    --app-scrollbar-thumb-hover: #036fa4,


    // Watchlist Component  
    --watchlist-text-color: #fff,
    --watchlist-up-direction-bg-color: #4C68EE,
    --watchlist-up-direction-color: linear-gradient(180deg,#6076ee,#2d3a83),
    --watchlist-default-direction-bg-color: #e8e9f1,
    --watchlist-default-direction-color: linear-gradient(180deg,#868895 25%,#676976),
    --watchlist-down-direction-bg-color: #B8191D,
    --watchlist-down-direction-color: linear-gradient(180deg,#bc1a1e,#770e10),
    // --watchlist-up-direction-bg-color: #bdd1ff,
    // --watchlist-up-direction-color: linear-gradient(180deg,#bdd1ff,#97b0ef),
    // --watchlist-default-direction-bg-color: #e8e9f1,
    // --watchlist-default-direction-color: linear-gradient(180deg,#e9eaf1 25%,#dcdef2),
    // --watchlist-down-direction-bg-color: #ffcaca,
    // --watchlist-down-direction-color: linear-gradient(180deg,#ffcaca,#f49a9a),

    //Table Component
    --table-input-bg-color: #fff,

    // Trading Account Status Component
    --account-status-bg-color: #fff,

    // Support Resistance Component
    --sr-price-bg-color: #EEE,

    // Table Component
    --table-tr-bg-color-hover: #ebedf6,
    --table-tr-diff-color: #e6e9f4,

    // Alarm Component
    --alarm-head-shadow-color: rgba(0, 0, 0, 0.1),

    // Modal Component
    --modal-bg-color: #fff,
    --modal-border-color: #ddd,
    --modal-header-bg: #ecedf1,
    --modal-secondary-border-color: #30324a,
    --modal-tertiary-border-color: #40454c,
    --modal-box-shadow-color: rgba(0, 0, 0, 0.1), 
    --modal-item-bg-color:#ebedf6,


    //Other Predefines
    --app-success:#46cd27,
    --app-danger:#F1416C,

    //Buy Cell
    --app-buy-color:#4C68EE,
    --app-sell-color:#EC363A,

    //Login & Register
    --auth-bg-color:linear-gradient(180deg, #4C68EE 0%, rgb(16 25 67) 100%),
    --auth-bg-right:#fff,
    --auth-primary-text-color:#ffffff,
    --auth-secondary-text-color:#2e2e35,
    --auth-input-bg-color:#ebedf6,
    --auth-input-border:transparent,

);