.strategies {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // margin-top: 2px;
    // height: 30px;
    height: 36px;
    min-height: 36px;
    background-color: var(--app-primary-color);
    font-family: var(--app-primary-font-family);
    font-size: 13px;
    color: #FFF;
    transition: all 0.12s ease-in-out;
    -o-transition: all 0.12s ease-in-out;
    -moz-transition: all 0.12s ease-in-out;
    -webkit-transition: all 0.12s ease-in-out;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 10px;

    &:hover {
        opacity: 0.85;
    }
}