.auth-layout {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--auth-bg-right);
    // background-color: var(--app-secondary-bg-color);
    .tm-login-info__wrapper {
        width: 50%;
        height: 100%;
        background: var(--auth-bg-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tm-info__content{
            max-width: 500px;
            .logo {
                width: 250px;
                height: 70px;
                background-image: var(--auth-logo-url);
                background-repeat: no-repeat;
                background-size: cover;
            }
            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 82px;
                color: #FFFFFF;
                margin: 0;
            }
            p{
                font-size: 18px;
                margin-top: 0;
                color: #FFFFFF;
            }
        }        
    }
}

.form-group.has-validation {
    padding-bottom: 24px !important;
    position: relative;
    margin-bottom: 10px !important;

    &.tm-form-val {
        .invalid-tooltip{
            font-size:14px;
        }
    }

    input {
        margin-bottom: 0px !important;
        border-color: #dc3545 !important;
        padding-right: calc(1.5em + 0.75rem);
        /*background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e);*/
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMiAxMicgd2lkdGg9JzEyJyBoZWlnaHQ9JzEyJyBmaWxsPSdub25lJyBzdHJva2U9JyNkYzM1NDUnPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

        &.is-valid {
            border-color: #198754 !important;
            padding-right: calc(1.5em + 0.75rem);
            /*background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e);*/
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMxOTg3NTQnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }

        &.is-invalid {
            border-color: #dc3545 !important;
            padding-right: calc(1.5em + 0.75rem);
            /*background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e);*/
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMiAxMicgd2lkdGg9JzEyJyBoZWlnaHQ9JzEyJyBmaWxsPSdub25lJyBzdHJva2U9JyNkYzM1NDUnPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
    }

    .invalid-tooltip {
        position: absolute;
        top: auto;
        z-index: 5;
        /*display: none;*/
        max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: 0.1rem;
        font-size: 10px;
        color: #fff;
        background-color: rgba(220,53,69,.9);
        border-radius: 8px;
        bottom: 0px;
        width: 100%;
    }

    .valid-tooltip {
        position: absolute;
        top: auto;
        z-index: 5;
        /*display: none;*/
        max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: 0.1rem;
        font-size: .875rem;
        color: #fff;
        background-color: rgba(25,135,84,.9);
        border-radius: 10px;
        bottom: 0px;
        width: 100%;
    }
}

.confirm-email {
    padding: 60px 0;

    .alert {
        display: flex;
        flex-direction: column;
        align-items: center;

        .alert-text {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
        }
    }

    button {
        width: 160px;
        border-radius: 15px;
        height: 50px;
        outline: none;
        color: #FFF;
        font-family: var(--app-primary-font-family);
        margin-bottom: 15px;
        cursor: pointer;
        background-color: var(--app-primary-color);
        border-color: transparent;
        margin-top: 20px;
        font-weight: bold;
        font-size: 18px;
    }
}