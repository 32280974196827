// Background
#modal-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

// Component
.modal {
    position: absolute;
    min-width: 340px;
    background-color: var(--modal-bg-color);
    // border: 2px solid var(--modal-border-color);
    border-radius: 8px;
    box-shadow: 0 0 20px -5px var(--modal-box-shadow-color);
    outline: none;
    z-index: 10;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
    border: 1px solid var(--modal-border-color);
    // Head
    & > .head {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 40px;
        border-bottom: 1px solid var(--modal-border-color);
        align-items: center;
        cursor: move;
        // Title
        .title {
            flex: 1;
            padding-left: 10px;
            font-family: var(--app-primary-font-family);
            font-size: 16px;
            font-weight: bold;
        }
        // Control Buttons
        .control-buttons {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            padding-right: 10px;

            button {
                padding: 0;
                background-color: transparent;
                color: var(--app-primary-text-color);
                font-size: 16px;
                border: none;
                outline: none;
                cursor: pointer;
            }
        }
    }

    .body {
        padding: 5px;
    }
}