@import './mixins';
@import './includes';
@import './components';
@import './responsive';


// Themes
@import 'themes/dark.theme.scss';
@import 'themes/light.theme.scss';

:root {
    &[theme=dark] {
        @include spread-map($dark-theme);
    }

    &[theme=light] {
        @include spread-map($light-theme);
    }
}

// Layouts
@import './layouts/main.layout.scss';
@import './layouts/auth.layout.scss';

// Containers
@import './containers/trader.container.scss';
@import './containers/login.container.scss';
@import './containers/register.container.scss';
@import './containers/forgot-password.container.scss';
@import './containers/reset-password.container.scss';
