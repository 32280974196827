/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media screen and (max-width: 767px) {
  .modal{
    transform: none !important;
  }
  .auth-layout {
    flex-direction: column !important;
    justify-content: flex-start !important;

    .tm-login-info__wrapper {
      width: 100% !important;
      padding: 15px;
      height: auto !important;

      .tm-info__content {
        h2 {
          font-size: 24px !important;
          line-height: 48px !important;
        }

        p {
          font-size: 14px !important;
        }

        .logo {
          background-size: contain;
          height: 60px;
          width: 210px;
        }
      }
    }

    .login-container,
    .register-container {
      width: 100%;
      padding: 0 15px;
      height: auto !important;
    }
  }

  .main-layout {
    overflow-y: auto !important;

    .trading-accounts {
      margin: unset !important;
      width: 100% !important;
      min-width: unset !important;

      .accounts {
        max-height: unset;
        overflow-y: auto;
      }
    }
  }

  .trader-container {
    >.header {
      max-width: 300px;
      flex-direction: column;
      min-height: 150px !important;

      .left {
        width: 100% !important;
        background-position: center !important;
        border-right: none !important;
        border-bottom: 1px solid var(--app-primary-border-color) !important;
      }

      .middle {
        border-bottom: 1px solid var(--app-primary-border-color);
        height: 50px;
        min-height: 50px;
        min-width: unset !important;
        .trading-account-status {
          overflow: hidden;
          overflow-x: scroll !important;
          .column{
            min-width: 100px;
          }
        }

      }
    }

    >.main {
      flex-direction: column;
      max-height: unset !important;

      .left {
        width: 100% !important;
        padding: 5px 15px !important;
        margin-bottom: 15px;

        .watchlist {
          .watchlist-item {
            .column {
              width: 50%;
            }
          }
        }
      }

      .middle {
        min-width: unset !important;
        min-height: 800px !important;
        padding: 5px 15px !important;
        margin-bottom: 15px;
        .top{
          .chart{
            .chart-header{
              background-color: #1e1e2d !important;
            border-bottom: 1px solid var(--app-primary-border-color);
            }
          }
        }
        .bottom {
          .datatables {
            .header {
              .tabs {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                button {
                  padding: 5px 5px !important;
                }
              }

              .controls {
                .hide-table {
                  span {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      .right {
        width: 100% !important;
        padding: 5px 15px !important;
        margin-bottom: 15px;
      }
    }

    .footer {
      .left {
        min-width: auto !important;
        width: 100% !important;
      }

      .middle {
        min-width: unset !important;
      }

      .right {
        min-width: auto !important;
        width: 100% !important;
      }
    }
  }

}

@media (min-width: 481px) and (max-width: 767px) {}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}