﻿#trading-accounts-modal {
    width: 600px;

    .body {
        max-height: 500px;
        overflow-y: scroll;
    }

    .accounts {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        .broker {
            padding: 15px;
            border: 1px solid var(--app-primary-border-color);
            border-radius: 10px;
            margin: 10px;
            min-width: 270px;
            background-color: var(--modal-item-bg-color);

            .title {
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 10px;
                padding-bottom: 5px;
                border-bottom: 1px solid var(--modal-border-color);
                font-family: var(--app-primary-font-family), sans-serif;
                font-weight: bold;
                font-size: 1.1rem;
                color: var(--app-primary-color);
            }
        }

        .platform {
            margin-bottom: 10px;
            font-family: var(--app-primary-font-family);

            .code {
                font-weight: bold;
            }

            ul {
                padding: 0px;
                margin: 10px;

                li {
                    margin: 10px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    span {
                        font-size: 14px;
                        font-weight: 400;

                        i {
                            color: var(--app-primary-color);
                            margin-right: 5px;
                            font-size: 14px;
                        }

                        &.selected-account {
                            width: 56px;
                            border: 1px solid #526ce7;
                            text-align: center;
                            border-radius: 5px;

                            i {
                                font-size: 24px !important;
                                margin-right: 0px !important;
                            }
                        }
                    }

                    & > * {
                        &:not(:last-child) {
                            margin-right: 10px;
                        }
                    }
                }
            }

            button {
                padding: 4px 10px;

                &:hover {
                    color: #fff;
                }

                &.p-outline-button {
                    border-color: var(--app-primary-color);
                    color: var(--app-primary-color);
                    width: 100%;
                    max-width: 240px;

                    &:hover {
                        background-color: var(--app-primary-color);
                        color: #fff;
                    }
                }
            }
        }

        ul {
            li {
                button {
                    border-radius: 5px;
                }
            }
        }
    }
}
