.page-loader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;

    .loader {
        position: absolute;
        width: 128px;
        height: 128px;
        margin-top: -80px;
        border-radius: 50%;
        perspective: 800px;
        // background-image: url(logo.svg);
        // background-image: url('../../assets/images/_/logo.svg');
        background-position: center center;
        background-size: 120px;
        background-repeat: none;
    }

    .message {
        color: var(--app-primary-text-color);
        margin-top: 100px;
    }

    .inner {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .inner.one {
        left: 0%;
        top: 0%;
        animation: rotate-one 1s linear infinite;
        border-bottom: 3px solid #efeffa;
    }

    .inner.two {
        right: 0%;
        top: 0%;
        animation: rotate-two 1s linear infinite;
        border-right: 3px solid #efeffa;
    }

    .inner.three {
        right: 0%;
        bottom: 0%;
        animation: rotate-three 1s linear infinite;
        border-top: 3px solid #efeffa;
    }

    &.close-effect {
        animation: fade-in 1s forwards;
    }
}

:root[theme=light] {
    .page-loader .loader {
        filter: invert(100%);
        -webkit-filter: invert(100%);
    }
}

@keyframes fade-in {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}
