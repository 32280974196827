// Create, Modify and Delete Alarm Modals
#create-alarm-modal, #modify-alarm-modal, #delete-alarm-modal, #notice-alarm-modal {
    width: 340px;

    .body {
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    label {
        margin-top: 5px;
        flex: 1;
        display: block;
        color: var(--app-secondary-text-color);
        font-size: 12px;

        &:first-child {
            padding-top: 5px;
        }
    }

    input {
        margin: 2px 1px;
        padding-left: 5px;
        width: calc(100% - 2px);
        height: 36px;
        max-height: 36px;
        font-size: 16px;
        background-color: transparent;
        border: 1px solid var(--app-buy-color);
        color: var(--app-primary-text-color);
        outline: none;
        border-radius: 8px;
    }

    select {
        margin: 2px 1px;
        padding-left: 5px;
        width: calc(100% - 2px);
        height: 36px;
        max-height: 36px;
        font-size: 16px;
        background-color: transparent;
        border: 1px solid var(--app-buy-color);
        color: var(--app-primary-text-color);
        outline: none;
        border-radius: 8px;
        
        option {
            background-color: var(--modal-bg-color);
            border: none;        
        }
    }

    form {
        .symbol {
            height: 25px;
            border-bottom: 1px solid var(--app-primary-border-color); 
            text-align: center;

            span {
                border-bottom: 2px solid var(--app-buy-color);
                padding: 5px;
                padding-bottom: 0;
                font-weight: 500;
            }
        }

        .submit-button {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            margin: 10px 0 0;
            width: 100%;
            height: 35px;
            font-size: 14px;
            color: var(--app-tertiary-text-color);
            background-color: var(--app-buy-color);
            border: none;
            transition: all 0.25s linear;
            -o-transition: all 0.25s linear;
            -moz-transition: all 0.25s linear;
            -webkit-transition: all 0.25s linear;
            cursor: pointer;
            border-radius: 8px;
            i {
                margin-left: 5px;
                display: inline-block;
                font-size: 20px;
                animation: spin 2s infinite;
                -o-animation: spin 2s infinite;
                -moz-animation: spin 2s infinite;
                -webkit-animation: spin 2s infinite;
            }

            &:disabled {
                // opacity: 0.8;
                color: var(--app-buy-color);
                background-color: transparent;
                border: 1px solid #30324a;
            }
            
            &:not(&:disabled):hover {
                opacity: 0.85;
            }
        }
    }
}

// Delete, Notice Alarm Modals
#delete-alarm-modal, #notice-alarm-modal {
    form {
        .message {
            display: flex;
            flex-direction: column;
            font-family: Lato,sans-serif;
            font-size: 14px;
            margin-top: 10px;
            text-align: center;
            padding: 0 30px;
        }
    }
}

// Alarm List Modal
#alarm-list-modal {
    width: 700px;

    .body {
        padding: 10px;
        height: 250px;
        overflow-y: auto;
    }

    table {
        width: 100%;
        text-align: left;
        font-size: 14px;
        text-align: center;

        thead {
            .options {
                width: 120px !important;
            }
        }

        tbody {
            button {
                height: 30px;
                border: none;
                border-radius: 3px;
                outline: none;
                font-family: inherit;
                transition: all 0.05s ease-in-out;
                -o-transition: all 0.05s ease-in-out;
                -moz-transition: all 0.05s ease-in-out;
                -webkit-transition: all 0.05s ease-in-out;
                cursor: pointer;
    
                &:hover {
                    opacity: 0.85;
                }

                &.modify {
                    background-color: var(--app-buy-color);
                    color: #FFF;
                }

                &.delete {
                    margin-left: 5px;
                    background-color: #bc1a1e;
                    color: #FFF;
                }
            }
        }
    }
}