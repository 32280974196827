.trading-account-status {
    flex: 1;
    display: flex;   
    
    // Column
    .column {
        flex: 1;
        text-align: center;
        color: var(--app-secondary-text-color);
        padding-top: 2px;
        padding-bottom: 4px;
        
        .title {
            font-size: 10px;
        }

        .status {
            font-size: 16px;
            font-weight: bold;
        }

        &:not(:last-child) {
            border-right: 1px solid var(--app-primary-border-color);
        }
    }
}