#symbol-list-modal {
    width: 600px;

    .body {
        padding: 10px;
    }

    // Head
    & .head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        // Tab
        .tab {
            display: flex;

            div {
                padding: 10px 20px;
                color: var(--app-primary-text-color);
                font-size: 14px;
                user-select: none;
                cursor: pointer;

                &.active {
                    border-bottom: 2px solid var(--app-buy-color);
                }
            }
        }

        // Controls
        .controls {
            user-select: none;

            input {
                padding-left: 5px;
                height: 30px;
                font-family: inherit;
                font-size: 14px;
                background-color: var(--app-primary-bg-color);
                border: 1px solid var(--app-primary-border-color);
                color: var(--app-primary-text-color);
                outline: none;

                &:focus {
                    border-color: var(--app-buy-color);
                }
            }
        }
    }

    // Content
    .content {
        height: 200px;
        overflow-y: auto;
        font-size: 14px;

        table {
            width: 100%;
            border-collapse: collapse;
        }

        thead {
            position: sticky;
            top: 0;
            background-color: var(--app-primary-border-color);
        }

        tbody {
            tr {
                &:hover {
                    // background-color: hsla(0,0%,100%,.04);
                    background-color: var(--table-tr-bg-color-hover);

                }
            }
        }
        
        th {
            padding: 4px 0;
            // text-transform: uppercase;
            font-size: 12px;
        }

        td {
            padding: 2px 0;
            color: var(--app-primary-text-color);
        }

        th, td {
            text-align: left;
        }

        img {
            &.checkbox {
                display: block;
                margin: auto;
                width: 20px;
                height: 20px;
            }
        }
    }
}