.chart {
    display: flex;
    flex-direction: column;
    height: 100%;

    // Header
    .chart-header {
        display: flex;   
        justify-content: space-between;
        font-size: 12px;

        // Tabs
        .tabs {
            button {
                height: 42px;
                background-color: transparent;
                color: var(--app-primary-text-color);
                border: none;
                border-right: 1px solid var(--app-primary-border-color);
                border-bottom: 1px solid var(--app-primary-border-color);
                outline: none;
                cursor: pointer;
                
                span {
                    margin-right: 5px;
                }

                &:hover {
                    color: #FFF;
                    background-color: var(--app-primary-color);
                }

                &.active {
                    color: #FFF;
                    background-color: var(--app-primary-color);
                }
            }
        }

        // Layouts
        .layout-selector {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            cursor: default;

            .row {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 150px;
                height: 42px;    
                padding-left: 10px;
                
                &.title {
                    background-color: var(--app-primary-color);
                    color: var(--app-tertiary-text-color);

                    i {
                        margin-right: 5px;
                    }

                    span {
                        margin-top: -2px;
                    }
                }
            }

            .container {
                display: none;
                position: absolute;
                flex-direction: column;
                margin-top: 42px;
                
                .row {
                    background-color: var(--app-primary-bg-color);
                    color: var(--app-primary-text-color);
                    cursor: pointer;

                    &:hover {
                        color: var(--app-primary-color);
                    }
                }
            }

            &:hover .container {
                display: flex;
            }
        }
    }

    // Chart Content
    .chart-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        user-select: none;

        .loader {
            position: absolute;
        }

        i {
            font-size: 50px;
            animation: spin 3s infinite;
            -o-animation: spin 3s infinite;
            -moz-animation: spin 3s infinite;
            -webkit-animation: spin 3s infinite;
        }

        span {
            margin-top: 100px;
            font-size: 14px;
        }

        iframe {
            border: none;
        }
    }
}