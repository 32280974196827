.one-click-trading-status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    min-height: 36px;
    font-family: var(--app-primary-font-family);
    font-size: 13px;
    color: var(--app-primary-color);
    border-color: var(--app-primary-color);
    // background-color: rgba(76, 104, 238, .3);
    background-color: rgba(76, 104, 238, .25);
    border-radius: 8px;
    margin-bottom: 5px;
    // margin-bottom: 7px;
    // border: 2px solid var(--app-primary-color);

}