#settings-modal {
    .body {
        width: 590px;
        padding: 10px;
    }

    .list-item {
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        .list-item-options {
            display: flex;

            & > * {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }

        .col-group {
            width: 200px;
            display: flex;
            flex-direction: column;
    
            & > * {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
    
        select {
            width: 160px;
            height: 30px;
            background-color: transparent;
            color: var(--app-primary-text-color);
            border: 1px solid var(--app-primary-border-color);
            outline: none;
            border-radius: 8px;
            cursor: pointer;
    
            option {
                background-color: var(--modal-bg-color);
                border: none;
            }
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .controls {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        button {
            padding: 0px 20px;
            width: 175px;
            height: 30px;
            border: none;
            outline: none;
            font-family: inherit;
            transition: all 0.15s ease-in-out;
            -o-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            -webkit-transition: all 0.15s ease-in-out;
            cursor: pointer;
            border-radius: 8px;

            &:hover {
                opacity: 0.85;
            }
        }

        & > * {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
        
        .reset-settings {
            background-color: #dc1d1d;
            color: #FFF;
        }

        .change-password {
            background-color: #d6a526;
            color: #FFF;
        }

        .save-changes {
            background-color: var(--app-primary-color);
            color: #FFF;
            width:200px;
        }
    }
}

#change-password-modal {
    .body {
        padding: 10px;
    }

    form {
        display: flex;
        flex-direction: column;
        font-size: 14px;

        input {
            margin: 5px 0 10px;
            padding-left: 5px;
            width: 100%;
            height: 30px;
            background-color: transparent;
            color: #e1e1e1;
            border: 1px solid var(--app-primary-border-color);
            outline: none;

            &:focus {
                border-color: var(--app-buy-color);
            }
        }

        button {
            height: 30px;
            border: none;
            outline: none;
            font-family: inherit;
            transition: all 0.15s ease-in-out;
            -o-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            -webkit-transition: all 0.15s ease-in-out;
            cursor: pointer;

            &:hover {
                opacity: 0.85;
            }
        }


        .change-password {
            background-color: var(--app-buy-color);
            color: #FFF;
        }
    }
    
}

#oneclicktrading-disclaimer-modal {
    .body {
        width: 640px;
        padding: 10px;
    }

    .content {
        padding: 0 10px 30px;
        height: 365px;
        overflow-y: auto;
        border: 1px solid var(--app-primary-border-color);

        .text-item {
            margin-top: 20px;

            b {
                font-size: 18px;
            }

            &:not(.title) {
                /*text-indent: 5px;*/
                font-size: 14px;
            }
        }

        .text-item-tab {
            text-indent: 15px;
        }
    }

    .controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .check-terms {
            label {
                padding-left: 5px;
                user-select: none;
            }
        }

        .control-buttons {
            display: flex;

            button {
                border-radius: 5px;
            }

            & > * {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }

    button {
        height: 30px;
        border: none;
        outline: none;
        font-family: inherit;
        transition: all 0.15s ease-in-out;
        -o-transition: all 0.15s ease-in-out;
        -moz-transition: all 0.15s ease-in-out;
        -webkit-transition: all 0.15s ease-in-out;
        cursor: pointer;

        &.confirm {
            background-color: var(--app-buy-color);
            color: #FFF;
        }

        &.cancel {
            background-color: #bc1a1e;
            color: #FFF;
        }

        &:hover {
            opacity: 0.85;
        }
    }
}