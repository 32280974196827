.open-orders-table {
    table {
        tbody {
            tr {
                transition: all 0.25s ease-in-out;

                td {
                    transition: all 0.25s ease-in-out;
                }

                &.create {
                    background-color: rgba(82, 108, 231, 0.15) !important;

                    td {
                        color: var(--app-primary-color);    
                    }
                }

                &.modify {
                    background-color: rgb(214, 165, 38, 0.15) !important;
                    
                    td {
                        color: #d6a526;    
                    }
                }
                
                &.close {
                    background-color: rgb(113, 0, 0, 0.15) !important;

                    td {
                        color: var(--app-red-text-color);
                    }
                }
            } 
        }
    }

    .chart-button {
        cursor: pointer;

        i {
            margin-left: 7px;
            font-size: 16px;
            color: var(--app-primary-color);
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .options {
        display: flex;
        
        button {
            flex: 1;
            margin-right: 5px;
            padding-left: 2px;
            padding-right: 2px;
            font-family: inherit;
            font-size: 12px;
            outline: none;
            border-radius: 5px;
            cursor: pointer;
            transition: all .1s ease-in-out;
            -o-transition: all .1s ease-in-out;
            -moz-transition: all .1s ease-in-out;
            -webkit-transition: all .1s ease-in-out;

            i {
                margin-left: 5px;
                font-size: 12px;
            }
        }
        
        .modify-button {
            border: 1px solid #d6a526;
            color: #d6a526;
            background-color: transparent;

            &:hover {
                color: var(--app-tertiary-text-color);
                background-color: #d6a526;
            }
        }
        
        .delete-button {
            border: 1px solid var(--app-primary-color);
            color: var(--app-primary-color);
            background-color: transparent;

            &:hover {
                color: var(--app-tertiary-text-color);
                background-color: var(--app-primary-color);
            }
        }
    }
}