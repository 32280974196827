.alarm {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 12px;
    z-index: 1;
    
    .title {
        margin-left: 5px;
    }

    .body {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        margin-top: -210px;
        padding: 5px;
        width: 310px;
        height: 180px;
        background-color: var(--app-primary-bg-color);
        border: 1px solid var(--app-primary-border-color);
        transition: all .15s ease-in-out;
        -o-transition: all .15s ease-in-out;
        -moz-transition: all .15s ease-in-out;
        -webkit-transition: all .15s ease-in-out;

        .head {
            display: flex;
            justify-content: space-between;
            height: 20px;
            color: var(--app-primary-color);
            background-color: var(--table-tr-bg-color-hover);
        }

        .content {
            height: 150px;
            padding-right: 5px;
            overflow-y: scroll;
        }

        button {
            padding: 0;
            margin: 0;
            background-color: transparent;
            color: inherit;
            border: none;
            outline: none;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        table {
            width: 100%;
            text-align: left;
            border-collapse: collapse;

            thead {
                position: sticky;
                top: 0;
                background-color: var(--app-primary-bg-color);
                border-bottom: 1px solid var(--alarm-head-shadow-color);
                box-shadow: 0 1px 2px var(--alarm-head-shadow-color);
            }

            th {
                padding: 3px 0;
                // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }
            
            td {
                padding: 3px 0;
            }

            .options {
                width: 90px;
            }
        }
    }

    &:hover .body {
        visibility: visible;
        opacity: 1;
    }
}