.radio-button {
    display: flex;
    user-select: none;
    align-items: center;

    .icon {
        height: 15px;
        overflow: hidden;

        img {
            transition: all 0.15s ease-in-out;
            -o-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            -webkit-transition: all 0.15s ease-in-out;
        }

        &.active {
            img {
                padding: 2px 0;
                margin-top: -17px;
            }
        }
    }

    span {
        margin-top: -2px;
        margin-left: 5px;
    }
}