.server-time {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    margin-right: 10px;
    color: var(--app-secondary-text-color);
    
    // Title
    .title {
        font-size: 12px;
    }

    // Time
    .time {
        margin-left: 5px;
        font-size: 13px;
        font-family: 'Lato', 'Poppins';
    }
}