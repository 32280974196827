.stock-exchange-times {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    width: 100%;
    height: 130px;
    
    // Map
    .map {
        margin: auto;
        
        svg {
            width: 150px;
            margin-bottom: 5px;
            fill: #446;
        }

        .map-green {
            fill: #02ff3c;
        }

        .map-red {
            fill: #df1b00;
        }
    }

    // Body 
    .body {
        width: 100%;
        display: flex;
        text-align: center;
        // margin-left: 10px;
        // margin-top: 6px;
        color: #9294a5;

        .stock-exchange {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;

            .name {
                font-size: 12px;
            }

            .status, .time {
                font-size: 10px;
            }
        } 
    }

    // Utils
    .red-text {
        color: red;
    }

    .green-text {
        color: #46cd27;
    }
}