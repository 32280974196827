.exposure-tool-table {
    // Toolbox
    .toolbox {
        display: flex;
        align-items: center;
        padding: 6px 10px;
        border-bottom: 1px solid var(--app-primary-border-color);

        & > * {
            margin-right: 10px;
        }

        .selector {
            letter-spacing: 1px;
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            user-select: none;
            
            .selected {
                position: relative;
                display: inline-block;
                margin: -3px;
                width: 200px;
                border: 1px solid var(--app-primary-border-color);
                padding: 2px 5px;
                cursor: default;
                border-radius: 5px;
                padding: 3px 5px;
                font-size: 10px;
                background-color: var(--table-input-bg-color);
                i {
                    position: absolute;
                    right: 5px;
                    top: 6px;
                }
            }
        
            .list {
                position: absolute;
                width: 200px;
                max-height: 150px;
                border: 1px solid var(--app-primary-border-color);
                background-color: var(--app-primary-bg-color);
                padding-top: 2px;
                padding-left: 5px;
                margin-top: -2px;
                margin-left: -3px;
                overflow-x: hidden;
                overflow-y: auto;
                letter-spacing: 0.4px;
                display: none;
                z-index: 5;
            }
            
            .list > div {
                padding: 2px 0;
                cursor: pointer;
                font-size: 10px;
            }
            
            .list > div:hover {
                opacity: 0.85;
            }
    
            &:hover .list {
                display: block;
            }
        }

        button {
            padding: 3px;
            background-color: var(--app-tertiary-button-color);
            color: var(--app-tertiary-button-text-color);
            font-family: inherit;
            font-size: 12px;
            border: none;
            outline: none;
            padding: 4px 10px;
            cursor: pointer;
            border-radius: 5px;
            height: 26px;
            &:hover {
                opacity: 0.9;
            }
        }
    }

    // Table
    table {
        .diff-color {
            border: 1px solid var(--app-primary-border-color);
            background-color: var(--table-tr-diff-color);
    
            &:hover {
                background-color: var(--app-secondary-bg-color) !important;
            }
        }
    }
}