.support-resistance-points {
    position: relative;
    min-width: 360px;
    height: 100%;
    background-color: var(--app-primary-bg-color);
    border: 1px solid var(--app-primary-border-color);
    overflow-y: scroll;
    transition: height 0.1s ease-in-out;
    -o-transition: height 0.1s ease-in-out;
    -moz-transition: height 0.1s ease-in-out;
    -webkit-transition: height 0.1s ease-in-out;
    z-index: 1;

    // Body
    .body {
        display: flex;
        flex-direction: column;
        padding: 1px 5px 40px;
    }

    // Items
    .row {
        display: flex;
        color: var(--app-secondary-text-color);

        .symbol {
            width: 60px;
            font-size: 11px;
        }

        .icons {
            width: 110px;
            font-size: 12px;
        }

        .description {
            margin-left: 5px;
            font-size: 10px;
        }
    }

    &:hover {
        height: 80px;
    }
}
