@import '../order-modal/styles.scss';
@import '../symbol-list/styles.scss';
@import '../automatic-system-modal/styles.scss';
@import '../settings-modal/styles.scss';
@import '../alarm-modal/styles.scss';
@import '../chart-modal/styles.scss';
@import '../trading-accounts/styles.scss';


input[type="number"] {
    -moz-appearance: textfield !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
