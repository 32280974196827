.trader-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    
    & > .header {
        width: fit-content;
        min-width: 100%;
        height: 45px;
        min-height: 45px;
        border-bottom: 1px solid var(--app-primary-border-color);

        & > .left {
            // background-image: var(--app-logo-url);
            background-repeat: no-repeat;
            background-size: 130px;
            background-position: 40px 0px;
            border-right: 1px solid var(--app-primary-border-color);
        }

        & > .middle {
            display: flex;
        }

        & > .right {
            border-left: 1px solid var(--app-primary-border-color);

        }
    }

    & > .main {
        width: 100%;
        flex: 1;
        max-height: calc(100% - 75px);

        & > .left {
            border-right: 1px solid var(--app-primary-border-color);
        }

        & > .middle {
            display: flex;
            flex-direction: column;

            .top {
                display: flex;
                flex-direction: column;
                flex: 1;
                height: 100%;
            }

            .bottom {
                height: 280px;
                min-height: 50px;
            }
        }

        & > .right {
            display: flex;
            flex-direction: column;
            border-left: 1px solid var(--app-primary-border-color);
            padding: 5px;

        }
    }

    & > .footer {
        width: fit-content;
        min-width: 100%;
        height: 30px;
        min-height: 30px;
        border-top: 1px solid var(--app-primary-border-color);
        background-color: var(--app-primary-bg-color);

        & > .left {
            border-right: 1px solid var(--app-primary-border-color);
        }
    }

    & > .header, & > .main, & > .footer {
        display: flex;

        .left {
            width: 245px;
            min-width: 245px;
            height: 100%;
        }

        .middle {
            width: 100%;
            min-width: 980px;
            flex: 1;
        }

        .right {
            width: 245px;
            min-width: 245px;
            height: 100%;
        }

        .flex-end {
            justify-content: flex-end;
        }
    }

    &.basic {
        .middle {
            min-width: initial !important;
        }
    }

    .horizantal-line {
        width: 100%;
        height: 5px;
        background-color: var(--app-primary-border-color);
        cursor: row-resize;

        &.hover, &:hover {
            background-color: var(--app-primary-color);
        }
    }
}
