#automatic-system-modal {
    .body {
        display: flex;
        flex-direction: column;
        width: 880px;
        padding: 10px 15px;
    }

    // Fields
    .top-content, .bottom-content {
        display: flex;
        margin-bottom: 10px;
    }

    // Field Contents: Left Side
    .master-strategies, .goals-and-strategies {
        flex: 1;
        margin-right: 1px;
        border: 1px solid var(--app-primary-border-color);
    }

    // Field Contents: Right Side
    .general-information, .risk-information {
        flex: 1;
        margin-left: 1px;
        border: 1px solid var(--app-primary-border-color);
    }

    // Master Strategies
    .master-strategies {
        .factsheet {
            background-color: #2196f3;
            background-color: var(--app-primary-color);
        }

        .invest {
            background-color: #1c8b0f;
        }

        .list-item {
            cursor: pointer;

            &:hover {
                background-color: var(--table-tr-bg-color-hover);
            }

        }
    }

    // Goals and Strategies
    .goals-and-strategies {
        .list-item-text {
            padding: 8px;
            font-size: 12px;
        }
    }

    // Risk Information
    .risk-information {
        .list-item {
            & > div {
                &:first-child {
                    flex: 1;
                }
    
                &:last-child {
                    text-align: right;
                    flex: 2;
                }
            }
        }
    }

    // List Title
    .list-title {
        padding: 5px;
        font-size: 14px;
        background-color: var(--modal-header-bg);
        font-weight: 600;
    }

    // List Item
    .list-item {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        padding: 8px;

        .list-item-content, .list-item-controls {
            display: flex;
            flex-direction: column;
        }

        .list-item-content {
            justify-content: center;
            
            .list-item-description {
                color: #9294a5;
            }
        }

        .list-item-controls {
            button {
                margin: 2px 0;
                width: 120px;
                height: 20px;
                font-family: inherit;
                font-size: 10px;
                color: #FFF;
                border: none;
                outline: none;
                transition: all 0.12s ease-in-out;
                -o-transition: all 0.12s ease-in-out;
                -moz-transition: all 0.12s ease-in-out;
                -webkit-transition: all 0.12s ease-in-out;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    opacity: 0.85;
                }
            }
        }

        &.active {
            background-color: var(--table-tr-bg-color-hover);
        }
    }

    .list-content {
        height: 260px;
        overflow-y: auto;
    }
}

#automatic-system-forwarding-modal {
    .body {
        padding: 10px 15px;
        width: 450px;
        font-size: 13px;
    }

    .more-information {
        margin-top: 20px;
        width: 120px;
        height: 30px;
        font-family: inherit;
        font-size: 12px;
        color: #FFF;
        background-color: #2196f3;
        border: none;
        outline: none;
        transition: all 0.12s ease-in-out;
        -o-transition: all 0.12s ease-in-out;
        -moz-transition: all 0.12s ease-in-out;
        -webkit-transition: all 0.12s ease-in-out;
        cursor: pointer;

        &:hover {
            opacity: 0.85;
        }
    }
}