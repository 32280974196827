.forgot-password-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--app-primary-font-family), sans-serif;
    font-size: 13px;
    width: 400px;

    .logo {
        width: 200px;
        margin: -40px 0 10px -10px;
        height: 40px;
        // background-image: var(--app-logo-url);
        background-repeat: no-repeat;
        background-size: cover;
    }

    h2.tm-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        color: var(--auth-secondary-text-color);
        text-align: left;
        width: 100%;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            margin: 15px 0;
            width: 50px;
            height: 5px;
            background: var(--app-primary-color);
        }
    }

    form {
        display: flex;
        margin-top: 20px;
        padding: 0;
        flex-direction: column;
        width: 100%;

        .form-group {
            label {
                margin-left: 2px;
                margin-bottom: 1px;
                font-weight: 500;
                color: var(--app-primary-text-color);
                margin-bottom: 5px;
                display: inline-block;
                font-size: 12px;
            }

            .textbox {
                width: 100%;
                display: flex;
                align-items: center;
                width: 100%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .input-group {
                    width: 100%;
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    input {
                        margin-bottom: 20px;
                        padding-left: 30px;
                        width: 100%;
                        height: 50px;
                        border: none;
                        color: var(--app-primary-color);
                        background-color: var(--auth-input-bg-color);
                        outline: none;
                        border: 2px solid var(--auth-input-border);
                        border-radius: 15px;
                        // &:-webkit-autofill,
                        // &:-webkit-autofill:hover,
                        // &:-webkit-autofill:focus,
                        // &:-webkit-autofill:active {
                        //     -webkit-transition: "color 2147483647s ease-out, background-color 2147483647s ease-out";
                        //     -webkit-transition-delay: 2147483647s;
                        //     -webkit-box-shadow: 0 0 0 30px var(--app-primary-bg-color) inset !important;
                        // }
                    }

                    i {
                        margin: 20px 2px 0 10px;
                        position: absolute;
                        float: left;
                        color: var(--app-primary-color);
                    }
                }
            }
        }



        .description {
            padding: 0px 10px 0 0;
            margin-bottom: 20px;
            text-align: left;
            cursor: default;

            span {
                color: var(--app-primary-color);
                text-decoration: underline;
                cursor: pointer;
                text-transform: capitalize;

                &:hover {
                    opacity: 0.9;
                }
            }
        }

        button {
            width: 100%;
            border-radius: 15px;
            height: 50px;
            outline: none;
            color: #FFF;
            font-family: var(--app-primary-font-family );
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 15px;
            cursor: pointer;

            &:last-child {
                margin-bottom: 10px;
            }

            &:hover {
                opacity: 0.85;
            }

            &.submit {
                background-color: var(--app-primary-color);
                border-color: transparent;

                &.btn-outline {
                    background-color: transparent;
                    border: 2px solid var(--app-primary-color);
                    color: var(--auth-secondary-text-color);
                }
            }
        }
    }

    .language-selector {
        display: flex;
        margin-top: 10px;

        & > * {
            margin: 0 2px;
        }

        .language {
            cursor: pointer;

            & > * {
                margin: 0 2px;
            }

            span {
                visibility: hidden;
                opacity: 0;
                transition: all 0.15s ease-in-out;
                -o-transition: all 0.15s ease-in-out;
                -moz-transition: all 0.15s ease-in-out;
                -webkit-transition: all 0.15s ease-in-out;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        &:hover span {
            visibility: visible;
            opacity: 1;
        }
    }
}
