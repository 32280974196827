// App styles
@import '../assets/styles/main.scss';

body {
    margin: 0;
    background-color: var(--app-primary-bg-color); 
    font-family: var(--app-primary-font-family), sans-serif;
    color: var(--app-primary-text-color); 
}

* {
    box-sizing: border-box;
}

.app {
    position: fixed;
    width: 100%;
    height: 100%;
}

// Buttons
.p-button {
    outline: none;
    border: none;
    border-radius: 4px;
    font-family: inherit;
    background-color: var(--app-primary-button-color);
    color: var(--app-primary-button-text-color);
    cursor: pointer;

    &:hover {
        background-color: var(--app-primary-button-color-hover);
        color: var(--app-primary-button-text-color-hover);
    }
}

.p-outline-button {
    outline: none;
    border: 1px solid var(--app-primary-button-color-hover);
    border-radius: 2px;
    font-family: inherit;
    background-color: transparent;
    color: var(--app-primary-button-color-hover);
    cursor: pointer;

    &:hover {
        background-color: var(--app-primary-button-color-hover);
        color: var(--app-primary-button-text-color-hover);
    }
}

.s-outline-button {
    outline: none;
    border: 1px solid var(--app-secondary-button-color-hover);
    border-radius: 2px;
    font-family: inherit;
    background-color: transparent;
    color: var(--app-secondary-button-text-color);
    cursor: pointer;

    &:hover {
        background-color: var(--app-secondary-button-color-hover);
        color: var(--app-secondary-button-text-color-hover);
    }
}

// Button Backgrounds

// Inputs
.p-input {
    padding-left: 10px;
    color: var(--app-secondary-text-color);
    background-color: var(--app-secondary-bg-color);
    border: none;
    border-radius: 5px;
    font-family: var(--app-primary-font-family), sans-serif;
    outline: none;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: var(--app-scrollbar-track);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--app-scrollbar-thumb);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--app-scrollbar-thumb-hover);
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

.mr-5{
    margin-right: 5px;
}
.mr-10{
    margin-right: 10px;
}
.mr-15{
    margin-right: 15px;
}

.ml-5{
    margin-left: 5px;
}
.ml-10{
    margin-left: 10px;
}
.ml-15{
    margin-left: 15px;
}

.mt-5{
    margin-top: 5px;
}
.mt-10{
    margin-top: 10px;
}
.mt-15{
    margin-top: 15px;
}

.mb-5{
    margin-bottom: 5px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-15{
    margin-bottom: 15px;
}