.accordion-list {
    flex: 1;
    overflow: hidden;
    
    .symbol-selector {
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        user-select: none;
        
        .analysis-symbol {
            position: relative;
            display: inline-block;
            width: 110px;
            height: 18px;
            color: var(--app-primary-text-color);
            border: 1px solid var(--app-primary-border-color);
            padding-left: 5px;
            cursor: default;
            border-radius: 6px;
            i {
                position: absolute;
                right: 5px;
            }
        }
    
        .symbol-list {
            position: absolute;
            width: 80px;
            height: 150px;
            border: 1px solid var(--app-primary-border-color);
            background-color: var(--app-primary-bg-color);
            padding-top: 2px;
            padding-left: 5px;
            margin-top: -2px;
            margin-left: -3px;
            overflow-x: hidden;
            overflow-y: auto;
            letter-spacing: 0.4px;
            display: none;
            z-index: 5;
        }
        
        .symbol-list .active {
            display: block;
        }
        
        .symbol-list > div {
            padding: 2px 0;
            cursor: pointer;
        }
        
        .symbol-list > div:hover {
            opacity: 0.85;
        }
    }

    .trend-table,
    .momentum-table {
        img {
            width: 100%;
            animation: fade-out 0.3s ease-in;
            -o-animation: fade-out 0.3s ease-in;
            -moz-animation: fade-out 0.3s ease-in;
            -webkit-animation: fade-out 0.3s ease-in;
        }

        .button {
            user-select: none;
            transition: all 0.15s ease-in-out;
            -o-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            -webkit-transition: all 0.15s ease-in-out;
            cursor: pointer;

            &.active-button {
                background-color: var(--app-tertiary-button-color);
                color: var(--app-tertiary-button-text-color);
            }
        }

        .loader {
            padding: 45px 0;
            color: #d6a526;
            font-size: 40px;
            animation: spin 3s infinite;
            -o-animation: spin 3s infinite;
            -moz-animation: spin 3s infinite;
            -webkit-animation: spin 3s infinite;
        }
    }

    .signal-table {
        .signal-icon {
            i {
                padding: 5px 0px;
                font-size: 40px;

                &.up-icon {
                    color: var(--app-primary-color);
                }

                &.down-icon {
                    color: #dc1d1d;
                }

                &.normal-icon {
                    color: #d6a526;
                }

                &.none-icon {
                    color: #d6a526;
                    animation: spin 3s infinite;
                    -o-animation: spin 3s infinite;
                    -moz-animation: spin 3s infinite;
                    -webkit-animation: spin 3s infinite;
                }
            }
        }
    }

    .support-resistance-table {
        .resistance-color {
            color: var(--app-blue-text-color);
        }

        .price-color {
            color: var(--app-primary-text-color);
            background-color: var(--modal-bg-color);
        }

        .support-color {
            color: var(--app-red-text-color);
        }
    }

    .level-table {
        .flex {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-left: 5px;
                width: 15px;
                height: 15px;
            }
        }
    }
}